<template>
  <div>
    <!-- Expend Tasks -->
    <v-checkbox v-model="expand" :label="labelExpandTasks"></v-checkbox>

    <!-- tasks -->
    <v-expansion-panels v-model="panels" multiple>
      <template v-for="task in tasks">
        <v-expansion-panel :key="`task-${task.id}`">
          <!-- header -->
          <v-expansion-panel-header disable-icon-rotate>
            <new-task-banner :task="task"></new-task-banner>
          </v-expansion-panel-header>

          <!-- content -->
          <v-expansion-panel-content eager>
            <new-project-task :task="task"></new-project-task>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
// design
import {
  iconDotsHorizontal,
  iconTasks,
  iconWarning
} from "@/design/icon/iconConst";

// model
import {
  findTaskPriority,
  priorityTextClass,
  userTaskLabel
} from "@/model/workflow/task/taskModel";
import { colorRecordDeletedClass } from "@/model/record/recordModel";

export default {
  name: "NewProjectTasks",
  components: {
    NewProjectTask: () => import("@/components/project/new/NewProjectTask"),
    NewTaskBanner: () => import("@/components/task/new/NewTaskBanner")
  },

  data() {
    return {
      iconTasks: iconTasks,
      expand: false,
      panels: [],
      iconMore: iconDotsHorizontal,
      iconWarning: iconWarning,
      colorRecordDeletedClass: colorRecordDeletedClass
    };
  },
  props: {
    /**
     * New Workflow Project
     * @type {{recordId: Number, projectName: string, comment: string, templateId: number, runOnlyOneProject: boolean, userTasks: {id: number, name: string, sequence: number, duration: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}}[]}}
     */
    project: undefined,

    /**
     * Valid Task Entries
     */
    validTasks: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * title
     * @return {string|string} title
     */
    title() {
      return this.taskCount > 1
        ? `${this.taskCount} ${userTaskLabel}s ${this.validTasks}`
        : `${this.taskCount} ${userTaskLabel}`;
    },

    /**
     * tasks
     * @return {{id: number, sequence: number}[]}
     */
    tasks() {
      return this.project?.userTasks ?? [];
    },

    /**
     * task Count
     * @return {number|number}
     */
    taskCount() {
      return this.tasks?.length ?? 0;
    },

    /**
     * label Expand Tasks
     * @return {string} formatted label Expend Tasks
     */
    labelExpandTasks() {
      return this.taskCount > 1
        ? `Expand ${this.taskCount} ${userTaskLabel}s`
        : `Expand ${this.taskCount} ${userTaskLabel}`;
    },

    /**
     * Task Validation Message
     * @return {string}
     */
    taskValidationMessage() {
      return "Not Valid Task Entries";
    }
  },
  methods: {
    /**
     * set Panels
     * @param {Boolean|boolean} expand expand all panels
     */
    setPanels(expand) {
      if (expand) {
        if ((this.tasks?.length ?? 0) === 0) {
          this.panels = [];
        } else {
          this.panels = this.tasks.map(el => {
            return this.tasks.indexOf(el);
          });
        }
      } else {
        this.panels = [];
      }
    },

    /**
     * display Priority
     * @param task
     * @return {string|string}
     */
    displayPriority(task) {
      return task ? `Priority ${findTaskPriority(task?.priority)?.text}` : "";
    },

    /**
     * priority Text Class
     * @param task
     * @return {String|string|undefined}
     */
    priorityTextClass(task) {
      return priorityTextClass(task);
    }
  },
  created() {
    /**
     * set expanding using local user settings here ...
     * @type {boolean}
     */
    this.expand = false;
  },
  watch: {
    expand(newValue) {
      this.setPanels(newValue);
    }
  }
};
</script>
